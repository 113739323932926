require("jquery");
import "bootstrap";

(function ($) {
    "use strict";

    $(document).ready(() => {
        /* Start back top */
        $(".back-top").on("click", function () {
            $("html").scrollTop(0);
        });

        // event change font size
        handleChangeFontSize();
    });

    // handle scroll event
    let timer_clear;
    $(window).scroll(function () {
        if (timer_clear) clearTimeout(timer_clear);

        timer_clear = setTimeout(function () {
            /* Start scroll back top */
            let $scrollTop = $(this).scrollTop();

            if ($scrollTop > 200) {
                $(".back-top").addClass("active");
            } else {
                $(".back-top").removeClass("active");
            }
        }, 100);
    });

    // handle change font size
    const handleChangeFontSize = () => {
        const body = $("body");
        const btnChangeFontSize = $(".btn-change-font-size");

        btnChangeFontSize.on("click", function () {
            const checkHasActive = $(this).hasClass("active");

            if (!checkHasActive) {
                const parent = $(this).closest(".option-change-font-size");
                const url = parent.data("url");
                const fontSize = $(this).data("font-size");

                // remove all class active
                parent.find(".btn-change-font-size").removeClass("active");
                // add class active btn click
                $(this).addClass("active");

                // ajax setting set font size
                $.ajax({
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                            "content"
                        ),
                    },
                    method: "get",
                    url: url,
                    dataType: "json",
                    data: {
                        type_font_size: fontSize,
                    },
                    success: function (result) {
                        if (result.status) {
                            window.location.reload();
                        }
                    },
                });
            }
        });
    };

    $(document).on("shown.bs.dropdown", ".dropdown-select-time", function() {
        $(this).find(".select-options").each(function(){
            $(this).scrollTop(0);
            if ($(this).find(".active").length) {
                $(this).scrollTop(parseInt($(this).find(".active").first().position().top) - 96);
            };
        })
    });

    $(document).on("click", ".dropdown-select-time .select-time .prev", function() {
        $(this).closest(".select-column").find(".select-options").first().animate({
            scrollTop: '-=32px'
        }, 0);
    })

    $(document).on("click", ".dropdown-select-time .select-time .next", function() {
        $(this).closest(".select-column").find(".select-options").first().animate({
            scrollTop: '+=32px'
        }, 0);
    })

    $(document).on("click", ".dropdown-select-time .select-time .dropdown-item", function() {
        let dropdown = $(this).closest(".dropdown-select-time");
        $(this).parent().find(".dropdown-item").not(this).removeClass("active");
        $(this).addClass("active");
        if (dropdown.find(".select-hour .active").length && dropdown.find(".select-minute .active").length) {
            dropdown.find(".dropdown-select-time-input").val(dropdown.find(".select-hour .active").text() + ":" + dropdown.find(".select-minute .active").text());
        }
    })

})(jQuery);
